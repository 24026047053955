<template>
  <Breadcrumbs title="Contact Us" />
  <!-- Address start -->
  <div class="bee-content-block">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="contact-top">
            <div class="row">
              <div class="col-12 col-lg-5">
                <div class="address">
                  <h3>Office Address</h3>
                  <div class="address-block">
                    <ul>
                      <li class="address-icon">
                        <strong>Adderss:</strong><br />
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                      </li>
                      <li class="phone-icon">
                        <strong>Telephone No:</strong><br />
                        +88 01711 123456
                      </li>
                      <li class="fax-icon">
                        <strong>Fax No:</strong><br />
                        +88 01711 123456
                      </li>
                      <li class="email-icon">
                        <strong>Email:</strong><br />
                        <a href="mailto:web24service@gmail.com" title="">web24service@gmail.com</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-7">
                <div class="contact-form">
                  <h3 class="heading3-border text-uppercase">Quick Feedback Form</h3>
                  <form action="#">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12 col-md-6 col-lg-6">
                          <input type="text" class="form-control" placeholder="Your Full Name" />
                        </div>
                        <div class="col-12 col-md-6 col-lg-6">
                          <input type="text" class="form-control" placeholder="Company" />
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12 col-md-6 col-lg-6">
                          <input type="email" class="form-control" placeholder="Email" />
                        </div>
                        <div class="col-12 col-md-6 col-lg-6">
                          <input type="text" class="form-control" placeholder="Phone Number" />
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <textarea class="form-control" placeholder="Message"></textarea>
                    </div>
                    <div class="form-group">
                      <button type="submit" class="btn bee-button">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Address End -->

      <!-- Map Start -->
      <div class="row">
        <div class="col-12">
          <div class="map">
            <iframe
              src="https://www.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=++Gulshan-1,+Dhaka-1212.&aq=&sll=23.78024,90.418081&sspn=0.01076,0.018475&ie=UTF8&hq=&hnear=1+Gulshan+Ave,+Gulshan,+Dhaka,+Dhaka+Division+1212,+Bangladesh&t=m&z=14&ll=23.780244,90.418078&output=embed"
            ></iframe>
          </div>
        </div>
      </div>
      <!-- Map End -->
    </div>
  </div>

  <!--Newsletter start -->
  <div class="bee-content-block newsletter-signup">
    <div class="container">
      <form action="#">
        <div class="row">
          <div class="col-12 col-sm-6 col-md-3 col-lg-3">
            <input class="form-control" type="text" placeholder="First Name" />
          </div>
          <div class="col-12 col-sm-6 col-md-3 col-lg-3">
            <input class="form-control" type="text" placeholder="Last Name" />
          </div>
          <div class="col-12 col-sm-6 col-md-3 col-lg-3">
            <input class="form-control" type="text" placeholder="Email" />
          </div>
          <div class="col-12 col-sm-6 col-md-3 col-lg-3">
            <input class="btn btn-bee-news" type="submit" value="Signup For Newsletter" />
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- Newsletter end -->
</template>

<script>
// @ is an alias to /src
// import BeeSlider from '@/components/Slider.vue';
import Breadcrumbs from "@/components/Breadcrumbs.vue";

export default {
  name: "About",
  components: {
    Breadcrumbs,
  },
};
</script>
